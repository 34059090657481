import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL + "/api";

export const updateProfile = (data, userId, token) => {
  return axios.put(`${apiURL}/auth/update-profile/${userId}`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const changePassword = (data, userId, token) => {
  return axios.put(`${apiURL}/auth/change-password/${userId}`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchDashboardCount = (token) => {
  return axios.get(`${apiURL}/count/dashboard`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchUsers = (page, token) => {
  return axios.get(`${apiURL}/user/all/${page}/10`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchStaffs = (page, token, type) => {
  return axios.get(`${apiURL}/staff/all/${page}/10/${type}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchStaff = (userId, token) => {
  return axios.get(`${apiURL}/staff/${userId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const addStaff = (data, token) => {
  return axios.post(`${apiURL}/staff/add`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const deleteStaff = (token, userId) => {
  return axios.delete(`${apiURL}/staff/${userId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchCareer = (token, id) => {
  return axios.get(`${apiURL}/career/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchCareers = (page, token, filter) => {
  return axios.get(`${apiURL}/career/all/${page}/10/${filter}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchTraining = (page, token, filter) => {
  return axios.get(`${apiURL}/training/all/${page}/10/${filter}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchImagesByPageName = (page, token) => {
  return axios.get(`${apiURL}/image/${page}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const updateImage = (id, data, token) => {
  return axios.put(`${apiURL}/image/${id}`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const getAllPrices = () => {
  return axios.get(`${apiURL}/price/all`);
};

export const updatePrice = (id, data, token) => {
  return axios.put(`${apiURL}/price/${id}`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const addBlog = (data, token) => {
  return axios.post(`${apiURL}/blog/add`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchBlogs = (page, token) => {
  return axios.get(`${apiURL}/blog/all/${page}/9`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchBlog = (id, token) => {
  return axios.get(`${apiURL}/blog/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const updateBlog = (id, data, token) => {
  return axios.put(`${apiURL}/blog/${id}`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const deleteBlog = (token, id, data) => {
  return axios.post(`${apiURL}/blog/del/${id}`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchConversation = (userId, token) => {
  return axios.get(`${apiURL}/chat/conversation/${userId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};
export const fetchUsersChat = (token) => {
  return axios.get(`${apiURL}/user/all/chat`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const sendChat = (token, data) => {
  return axios.post(`${apiURL}/chat/send`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchChatMessage = (token, userId, conversationId) => {
  return axios.get(`${apiURL}/chat/all/${userId}/${conversationId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const sendMessage = (token, data) => {
  return axios.post(`${apiURL}/message/add`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchMessage = (page, token) => {
  return axios.get(`${apiURL}/message/all/${page}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const addPortfolio = (data, token) => {
  return axios.post(`${apiURL}/portfolio/add`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchPortfolios = (token) => {
  return axios.get(`${apiURL}/portfolio/all`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchPortfolio = (id, token) => {
  return axios.get(`${apiURL}/portfolio/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const updatePortfolio = (id, data, token) => {
  return axios.put(`${apiURL}/portfolio/${id}`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const deletePortfolio = (token, id, data) => {
  return axios.post(`${apiURL}/portfolio/del/${id}`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchTestimonials = (token) => {
  return axios.get(`${apiURL}/testimonial/all`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const updateTestimonial = (id, data, token) => {
  return axios.put(`${apiURL}/testimonial/${id}`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchServices = (token) => {
  return axios.get(`${apiURL}/service/all`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const updateService = (id, data, token) => {
  return axios.put(`${apiURL}/service/${id}`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchSiteInfo = (token) => {
  return axios.get(`${apiURL}/site/all`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const updateSiteInfo = (id, data, token) => {
  return axios.put(`${apiURL}/site/${id}`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const addTeam = (data, token) => {
  return axios.post(`${apiURL}/team/add`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchTeams = (page, token) => {
  return axios.get(`${apiURL}/team/all/${page}/100`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchTeam = (id, token) => {
  return axios.get(`${apiURL}/team/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const updateTeam = (id, data, token) => {
  return axios.put(`${apiURL}/team/${id}`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const deleteTeam = (token, id, data) => {
  return axios.post(`${apiURL}/team/del/${id}`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};
