import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "../common/Button";
import Loading from "../components/Loading";
import {
  ErrorMessage,
  Input,
  Textarea,
} from "../components/styledComponent/formInputs";
import { Context } from "../context/MainContext";
import { fetchSiteInfo, updateSiteInfo } from "../api/dashboardApi";

const WebsiteInfo = () => {
  const [logo, setLogo] = useState("");
  const [changeImage, setChangeImage] = useState(false);
  const [email, setEmail] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [phone3, setPhone3] = useState("");
  const [address, setAddress] = useState("");
  const [twitter, setTwitter] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [youtube, setYoutube] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [inputError, setInputError] = useState({});
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [siteInfo, setSiteInfo] = useState("");
  const { user } = useContext(Context);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file?.size > 12240) {
      toast.warning("File too big, max size 10kb");
    } else {
      const base64 = await convertToBase64(file);

      setChangeImage(true);
      setLogo(base64);
    }
  };

  const validate = () => {
    let emailError = "";
    let phone1Error = "";
    let phone2Error = "";
    let phone3Error = "";
    let addressError = "";
    let twitterError = "";
    let facebookError = "";
    let instagramError = "";
    let youtubeError = "";
    let linkedinError = "";

    if (!email) {
      emailError = "email is required";
    }
    if (!email.includes("@")) {
      emailError = "email is invalid";
    }
    if (!phone1) {
      phone1Error = "phone 1 is required";
    }
    if (!phone2) {
      phone2Error = "phone 2 is required";
    }
    if (!phone3) {
      phone3Error = "phone 3 is required";
    }
    if (!address) {
      addressError = "address is required";
    }
    if (!twitter) {
      twitterError = "twitter is required";
    }
    if (!facebook) {
      facebookError = "facebook is required";
    }
    if (!instagram) {
      instagramError = "instagram is required";
    }
    if (!youtube) {
      youtubeError = "youtube is required";
    }
    if (!linkedin) {
      linkedinError = "linkedin is required";
    }

    if (
      emailError ||
      phone1Error ||
      phone2Error ||
      phone3Error ||
      addressError ||
      twitterError ||
      facebookError ||
      instagramError ||
      youtubeError ||
      linkedinError
    ) {
      setInputError((curr) => {
        return {
          ...curr,
          email: emailError,
          phone1: phone1Error,
          phone2: phone2Error,
          phone3: phone3Error,
          address: addressError,
          twitter: twitterError,
          facebook: facebookError,
          instagram: instagramError,
          youtube: youtubeError,
          linkedin: linkedinError,
        };
      });
      return false;
    }
    return true;
  };

  const handleEditProfile = async () => {
    const checkValidate = validate();
    if (checkValidate) {
      setInputError({});
      setLoading(true);
      try {
        let data = {};
        if (!changeImage) {
          data = {
            email,
            phone1,
            phone2,
            phone3,
            address,
            twitter,
            facebook,
            linkedin,
            instagram,
            youtube,
          };
        } else {
          data = {
            logo,
            email,
            phone1,
            phone2,
            phone3,
            address,
            twitter,
            facebook,
            linkedin,
            instagram,
            youtube,
          };
        }

        await updateSiteInfo(siteInfo?.id, data, user?.accessToken);

        setLoading(false);
        toast.success("Website info update successfully");
        setRefresh((curr) => !curr);
        setChangeImage(false);
        setEdit(false);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };

  useEffect(() => {
    const handleFetchSiteInfo = async () => {
      if (user?.id) {
        setLoading(true);
        try {
          const res = await fetchSiteInfo(user?.accessToken);

          setSiteInfo(res.data);
          setLogo(res.data?.logo);
          setAddress(res.data?.address);
          setEmail(res.data?.email);
          setYoutube(res.data?.youtube);
          setPhone1(res.data?.phone1);
          setPhone2(res.data?.phone2);
          setPhone3(res.data?.phone3);
          setLinkedin(res.data?.linkedin);
          setInstagram(res.data?.instagram);
          setFacebook(res.data?.facebook);
          setTwitter(res.data?.twitter);

          console.log("res.data", res.data);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          const message =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
          toast.error(message);
        }
      }
    };

    handleFetchSiteInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, refresh]);

  return (
    <div className="websiteInfo">
      <div className="profile__main">
        <h2
          className={
            edit ? "profile__main__title active" : "profile__main__title"
          }
        >
          WEBSITE INFO
          <i
            onClick={() => setEdit((curr) => !curr)}
            className="feather ft-edit"
          ></i>
        </h2>

        <div className="profile__main__con">
          <div className="profile__main__img">
            <img
              src={logo}
              alt=""
              style={{ width: "100%", height: "auto", background: "#022d62" }}
            />
            {edit && (
              <input
                type="file"
                onChange={(e) => handleFileUpload(e)}
                accept="image/png, image/gif, image/jpeg, image/jpg, .svg"
              />
            )}
          </div>

          <div className="profile__main__info">
            <div className="profile__main__info__item">
              <b>Email</b>

              {!edit ? (
                <span>{siteInfo?.email}</span>
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Input
                    width="250px"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <ErrorMessage>{inputError?.email}</ErrorMessage>
                </div>
              )}
            </div>
            <div className="profile__main__info__item">
              <b>Phone 1</b>

              {!edit ? (
                <span>{siteInfo?.phone1}</span>
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Input
                    width="250px"
                    value={phone1}
                    onChange={(e) => setPhone1(e.target.value)}
                  />
                  <ErrorMessage>{inputError?.phone1}</ErrorMessage>
                </div>
              )}
            </div>
            <div className="profile__main__info__item">
              <b>Phone 2</b>

              {!edit ? (
                <span>{siteInfo?.phone2}</span>
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Input
                    width="250px"
                    value={phone2}
                    onChange={(e) => setPhone2(e.target.value)}
                  />
                  <ErrorMessage>{inputError?.phone2}</ErrorMessage>
                </div>
              )}
            </div>
            <div className="profile__main__info__item">
              <b>Phone(whatsapp)</b>

              {!edit ? (
                <span>{siteInfo?.phone3}</span>
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Input
                    width="250px"
                    value={phone3}
                    onChange={(e) => setPhone3(e.target.value)}
                  />
                  <ErrorMessage>{inputError?.phone3}</ErrorMessage>
                </div>
              )}
            </div>

            <div
              //   style={{ margin: 30, marginTop: "0px" }}
              className="profile__main__info__item"
            >
              <b>Address</b>

              {!edit ? (
                <span>{siteInfo?.address}</span>
              ) : (
                <Textarea
                  width="250px"
                  value={address}
                  rows={3}
                  onChange={(e) => setAddress(e.target.value)}
                />
              )}
            </div>
            <div
              //   style={{ margin: 30, marginTop: "0px" }}
              className="profile__main__info__item"
            >
              <b>Twitter </b>

              {!edit ? (
                <span>{siteInfo?.twitter}</span>
              ) : (
                <Textarea
                  width="250px"
                  value={twitter}
                  rows={3}
                  onChange={(e) => setTwitter(e.target.value)}
                />
              )}
            </div>
            <div
              //   style={{ margin: 30, marginTop: "0px" }}
              className="profile__main__info__item"
            >
              <b>Youtube</b>

              {!edit ? (
                <span>{siteInfo?.youtube}</span>
              ) : (
                <Textarea
                  width="250px"
                  value={youtube}
                  rows={3}
                  onChange={(e) => setYoutube(e.target.value)}
                />
              )}
            </div>
            <div
              //   style={{ margin: 30, marginTop: "0px" }}
              className="profile__main__info__item"
            >
              <b>Facebook</b>

              {!edit ? (
                <span>{siteInfo?.facebook}</span>
              ) : (
                <Textarea
                  width="250px"
                  value={facebook}
                  rows={3}
                  onChange={(e) => setFacebook(e.target.value)}
                />
              )}
            </div>
            <div
              //   style={{ margin: 30, marginTop: "0px" }}
              className="profile__main__info__item"
            >
              <b>Instagram</b>

              {!edit ? (
                <span>{siteInfo?.instagram}</span>
              ) : (
                <Textarea
                  width="250px"
                  value={instagram}
                  rows={3}
                  onChange={(e) => setInstagram(e.target.value)}
                />
              )}
            </div>
            <div
              //   style={{ margin: 30, marginTop: "0px" }}
              className="profile__main__info__item"
            >
              <b>Linkedin</b>

              {!edit ? (
                <span>{siteInfo?.linkedin}</span>
              ) : (
                <Textarea
                  width="250px"
                  value={linkedin}
                  rows={3}
                  onChange={(e) => setLinkedin(e.target.value)}
                />
              )}
            </div>
          </div>
        </div>

        {edit && (
          <div className="profile__main__btn">
            <Button height="40px" onClick={handleEditProfile}>
              {loading ? <Loading button={true} /> : "Save"}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default WebsiteInfo;
