import React, { useState } from "react";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import Loading from "./Loading";
import { toast } from "react-toastify";
import { deleteTeam } from "../api/dashboardApi";

const TeamItem = ({ team, setRefresh, user }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    if (user?.id) {
      setLoading(true);
      try {
        const data = {
          url: team?.coverUrl,
        };

        await deleteTeam(user?.accessToken, team?.id, data);

        toast.success("Team deleted successfully");
        setModalIsOpen(false);
        setLoading(false);
        setRefresh((curr) => !curr);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };

  return (
    <>
      <ReactModal
        className="modalMain"
        overlayClassName="modal-overlayCenter"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Cart Modal"
      >
        <div className="tableMenu__modal">
          <h4 style={{ marginBottom: 10 }}>Are you sure</h4>
          <p>
            You are about to delete a team member, this process cannot be undone
          </p>
          <div className="tableMenu__modal__btn">
            <button
              className="tableMenu__modal__btn__item"
              onClick={() => setModalIsOpen(false)}
            >
              Cancel
            </button>
            <button
              onClick={handleDelete}
              className="tableMenu__modal__btn__item delete"
            >
              {loading ? <Loading button={true} /> : "Delete"}
            </button>
          </div>
        </div>
      </ReactModal>
      <div className="blog__main__list__item">
        <img src={team?.imageUrl} alt="" />
        <div className="blog__main__list__item__date">
          <p>{team?.title}</p>
          <h4>{team?.name}</h4>
        </div>

        <div className="blog__main__list__item__action">
          <Link to={"/dashboard/image/team/edit/" + team?.id}>
            <i className="feather ft-edit"></i>
          </Link>
          <i className="ti-trash del" onClick={() => setModalIsOpen(true)}></i>
        </div>
      </div>
    </>
  );
};

export default TeamItem;
