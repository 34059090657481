import "bootstrap/dist/css/bootstrap.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import NotFound from "./pages/NotFound";
import AuthLayout from "./layouts/AuthLayout";
import "./assets/styles/scss/main.scss";
import Login from "./pages/Login";
// import Signup from "./pages/Signup";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import VerifyMail from "./pages/VerifyMail";
import Settings from "./pages/Settings";
import DashboardLayout from "./layouts/DashboardLayout";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/Dashboard";
import Staff from "./pages/Staff";
import AddStaff from "./pages/AddStaff";
import EditStaff from "./pages/EditStaff";
import Careers from "./pages/Careers";
import CareerApp from "./pages/CareerApp";
import TrainingApp from "./pages/TrainingApp";
import CareerItem from "./pages/CareerItem";
import ImagesHome from "./pages/ImagesHome";
import Prices from "./pages/Prices";
import Blog from "./pages/Blog";
import AddBlog from "./pages/AddBlog";
import "react-quill/dist/quill.snow.css";
import EditBlog from "./pages/EditBlog";
import Chat from "./pages/Chat";
import "react-tooltip/dist/react-tooltip.css";
import Message from "./pages/Message";
import Portfolio from "./pages/Portfolio";
import AddPortfolio from "./pages/AddPortfolio";
import EditPortfolio from "./pages/EditPortfolio";
import Testimonials from "./pages/Testimonials";
import Service from "./pages/Service";
import { useContext } from "react";
import { Context } from "./context/MainContext";
import Training from "./pages/Training";
import StaffDashboard from "./pages/StaffDashboard";
import OurTeam from "./pages/OurTeam";
import EditTeam from "./pages/EditTeam";
import AddTeam from "./pages/AddTeam";
// import Signup from "./pages/Signup";

function App() {
  const { user } = useContext(Context);

  return (
    <div className="App">
      <ToastContainer position="top-right" />
      <Router>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/career-application" element={<CareerApp />} />
          <Route path="/training-application" element={<TrainingApp />} />
          <Route path="/" element={<AuthLayout />}>
            <Route index element={<Login />} />
            <Route path="login" element={<Login />} />
            {/* <Route path="signup" element={<Signup />} /> */}
            <Route path="forgot-password" element={<ForgetPassword />} />
            <Route path="verify-mail/:id" element={<VerifyMail />} />
            <Route path="reset-password/:id" element={<ResetPassword />} />
          </Route>

          <Route path="/dashboard" element={<DashboardLayout />}>
            {user?.role === "admin" || user?.role === "hr" ? (
              <Route index element={<Dashboard />} />
            ) : (
              <Route index element={<StaffDashboard />} />
            )}
            <Route path="chat" element={<Chat />} />
            <Route path="message" element={<Message />} />
            <Route path="settings" element={<Settings />} />

            {(user?.role === "admin" || user?.role === "hr") && (
              <>
                <Route path="users/staff" element={<Staff type="Staff" />} />
                <Route
                  path="users/subadmin"
                  element={<Staff type="Subadmin" />}
                />
                <Route path="users/hr" element={<Staff type="HR" />} />
                <Route path="users/admin" element={<Staff type="Admin" />} />
                <Route path="add-staff" element={<AddStaff type="Staff" />} />
                <Route
                  path="add-subadmin"
                  element={<AddStaff type="Subadmin" />}
                />
                <Route path="add-hr" element={<AddStaff type="HR" />} />
                <Route path="add-admin" element={<AddStaff type="Admin" />} />
                <Route path="edit-staff/:id" element={<EditStaff />} />
              </>
            )}

            {(user?.role === "admin" ||
              user?.role === "hr" ||
              user?.role === "subadmin") && (
              <>
                <Route path="careers" element={<Careers />} />
                <Route path="careers/:id" element={<CareerItem />} />
                <Route path="trainings" element={<Training />} />
              </>
            )}

            {(user?.role === "admin" || user?.role === "subadmin") && (
              <>
                <Route path="blogs" element={<Blog />} />
                <Route path="blogs/add" element={<AddBlog />} />
                <Route path="blogs/edit/:id" element={<EditBlog />} />
                <Route path="portfolio" element={<Portfolio />} />
                <Route path="portfolio/add" element={<AddPortfolio />} />
                <Route path="portfolio/edit/:id" element={<EditPortfolio />} />
              </>
            )}

            {user?.role === "admin" && (
              <>
                <Route path="price" element={<Prices />} />
                <Route path="testimonials" element={<Testimonials />} />
                <Route path="services" element={<Service />} />
                <Route path="image/home" element={<ImagesHome type="Home" />} />
                <Route
                  path="image/about"
                  element={<ImagesHome type="About" />}
                />

                <Route path="image/team" element={<OurTeam />} />
                <Route path="image/team/edit/:id" element={<EditTeam />} />
                <Route path="image/team/add" element={<AddTeam />} />
                {/* <Route
                  path="image/team"
                  element={<ImagesHome type="Our Team" />}
                /> */}
                {/* <Route
                  path="image/value"
                  element={<ImagesHome type="Our Value" />}
                /> */}
              </>
            )}
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
