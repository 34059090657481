import { Link, useLocation } from "react-router-dom";
import logoIcon from "../assets/images/svg/logo.svg";
import SideBarDropdown from "./SideBarDropdown";
import { useContext, useEffect, useState } from "react";
import { Context } from "../context/MainContext";

const Sidebar = ({ drawer, setModalIsOpen }) => {
  const { pathname } = useLocation();
  const [links, setLinks] = useState([]);
  const { user } = useContext(Context);
  useEffect(() => {
    if (user?.role === "admin") {
      setLinks([
        { name: "Dashboard", url: "", iconClassName: "feather ft-home" },
        {
          name: "Users",
          iconClassName: "feather ft-users",
          dropDown: [
            {
              name: "Staff",
              url: "users/staff",
            },
            {
              name: "Subadmin",
              url: "users/subadmin",
            },
            {
              name: "HR",
              url: "users/hr",
            },
            {
              name: "Admin",
              url: "users/admin",
            },
          ],
        },
        {
          name: "Career Application",
          url: "careers",
          iconClassName: "feather ft-file",
        },
        {
          name: "Training Application",
          url: "trainings",
          iconClassName: "feather ft-credit-card",
        },
        {
          name: "Portfolio",
          url: "portfolio",
          iconClassName: "feather ft-briefcase",
        },
        {
          name: "Blogs",
          url: "blogs",
          iconClassName: "ti-files",
        },
        {
          name: "Image",
          iconClassName: "feather ft-image",
          dropDown: [
            {
              name: "Home Page",
              url: "image/home",
            },
            {
              name: "About us",
              url: "image/about",
            },
            {
              name: "Our Team",
              url: "image/team",
            },
            // {
            //   name: "Our Value",
            //   url: "image/value",
            // },
          ],
        },
        {
          name: "Price List",
          url: "price",
          iconClassName: "fa fa-money",
        },
        {
          name: "Testimonials",
          url: "testimonials",
          // iconClassName: "fa fa-list",
          iconClassName: "ti-comment-alt",
        },
        {
          name: "Services",
          url: "services",
          iconClassName: "feather ft-list",
        },
        {
          name: "Message",
          url: "message",
          iconClassName: "fa fa-envelope-o",
        },
        {
          name: "Chat",
          url: "chat",
          iconClassName: "fa fa-comment-o",
        },
        {
          name: "Settings",
          url: "settings",
          iconClassName: "feather ft-settings",
        },
      ]);
    }

    if (user?.role === "staff") {
      setLinks([
        { name: "Dashboard", url: "", iconClassName: "feather ft-home" },
        {
          name: "Message",
          url: "message",
          iconClassName: "fa fa-envelope-o",
        },
        {
          name: "Chat",
          url: "chat",
          iconClassName: "fa fa-comment-o",
        },
        {
          name: "Settings",
          url: "settings",
          iconClassName: "feather ft-settings",
        },
      ]);
    }

    if (user?.role === "subadmin") {
      setLinks([
        { name: "Dashboard", url: "", iconClassName: "feather ft-home" },

        {
          name: "Career Application",
          url: "careers",
          iconClassName: "feather ft-file",
        },
        {
          name: "Training Application",
          url: "trainings",
          iconClassName: "feather ft-credit-card",
        },
        {
          name: "Portfolio",
          url: "portfolio",
          iconClassName: "feather ft-briefcase",
        },
        {
          name: "Blogs",
          url: "blogs",
          iconClassName: "ti-files",
        },

        {
          name: "Message",
          url: "message",
          iconClassName: "fa fa-envelope-o",
        },
        {
          name: "Chat",
          url: "chat",
          iconClassName: "fa fa-comment-o",
        },
        {
          name: "Settings",
          url: "settings",
          iconClassName: "feather ft-settings",
        },
      ]);
    }
    if (user?.role === "hr") {
      setLinks([
        { name: "Dashboard", url: "", iconClassName: "feather ft-home" },
        {
          name: "Users",
          iconClassName: "feather ft-users",
          dropDown: [
            {
              name: "Staff",
              url: "users/staff",
            },
            {
              name: "Subadmin",
              url: "users/subadmin",
            },
            {
              name: "HR",
              url: "users/hr",
            },
            {
              name: "Admin",
              url: "users/admin",
            },
          ],
        },
        {
          name: "Career Application",
          url: "careers",
          iconClassName: "feather ft-file",
        },
        {
          name: "Training Application",
          url: "trainings",
          iconClassName: "feather ft-credit-card",
        },

        {
          name: "Message",
          url: "message",
          iconClassName: "fa fa-envelope-o",
        },
        {
          name: "Chat",
          url: "chat",
          iconClassName: "fa fa-comment-o",
        },
        {
          name: "Settings",
          url: "settings",
          iconClassName: "feather ft-settings",
        },
      ]);
    }
  }, [user?.role]);

  return (
    <nav className={drawer ? "sidebar draw" : "sidebar"}>
      <div>
        <div className="sidebar__top">
          <img src={logoIcon} alt="" className="sidebar__top__logo" />
        </div>
        <ul className="sidebar__list">
          {links.map((link) => {
            return (
              <>
                {!link.dropDown ? (
                  <Link
                    key={link.name}
                    to={
                      link.url === "" ? "/dashboard" : "/dashboard/" + link.url
                    }
                    onClick={() => drawer && setModalIsOpen(false)}
                  >
                    <li
                      className={
                        pathname.includes(link.url) && link.name !== "Dashboard"
                          ? "sidebar__list__item active"
                          : pathname === "/dashboard" &&
                            link.name === "Dashboard"
                          ? "sidebar__list__item active"
                          : "sidebar__list__item"
                      }
                    >
                      <i className={link.iconClassName}></i>
                      {link.name}
                    </li>
                  </Link>
                ) : (
                  <SideBarDropdown
                    key={link.name}
                    pathname={pathname}
                    setModalIsOpen={setModalIsOpen}
                    link={link}
                    drawer={drawer}
                  />
                )}
              </>
            );
          })}
        </ul>
      </div>

      {/* <button className="btn sidebar__btn" onClick={handleLogout}>
        Logout
      </button> */}
    </nav>
  );
};

export default Sidebar;
