import { useContext, useEffect, useState } from "react";
import {
  ErrorMessage,
  Input,
  Label,
  Textarea,
} from "../components/styledComponent/formInputs";
import { toast } from "react-toastify";
import Button from "../common/Button";
import Loading from "../components/Loading";
import { Context } from "../context/MainContext";
import { Link, useParams } from "react-router-dom";
import { addTeam, fetchTeam, updateTeam } from "../api/dashboardApi";

const EditTeam = () => {
  const { user } = useContext(Context);
  const [inputError, setInputError] = useState({});
  const [fileName, setfileName] = useState("");
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [uploadedFile, setuploadedFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMain, setLoadingMain] = useState(false);
  const [changeImage, setChangeImage] = useState(false);
  const [image, setImage] = useState("");

  const { id } = useParams();

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];

    if (file?.size > 229715) {
      toast.warning("File too big, max size 200kb");
    } else {
      const file = e.target.files[0];
      const base64 = await convertToBase64(file);

      setChangeImage(true);
      setImage(base64);
      setfileName(file?.name);
      setuploadedFile(file);
    }
  };

  const validate = () => {
    let titleError = "";
    let nameError = "";
    // let uploadedFileError = "";

    if (!title) {
      titleError = "title is required";
    }
    if (!name) {
      nameError = "name is required";
    }
    // if (!uploadedFile) {
    //   uploadedFileError = "cover image is required";
    // }

    if (titleError || nameError) {
      setInputError((curr) => {
        return {
          ...curr,
          title: titleError,
          name: nameError,
          // uploadedFile: uploadedFileError,
          // youtube: youtubeError,
        };
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    const checkValidate = validate();
    if (checkValidate) {
      setInputError({});
      setLoading(true);
      try {
        let formData = new FormData();

        if (changeImage) {
          formData.append("title", title);
          formData.append("name", name);
          formData.append("uploadedFile", uploadedFile);
          formData.append("imageUrl", imageUrl);
        } else {
          formData.append("title", title);
          formData.append("name", name);
        }

        updateTeam(id, formData, user?.accessToken);

        setLoading(false);
        toast.success("Updated successfully");
        setChangeImage(false);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };

  useEffect(() => {
    const handleFetchBlog = async () => {
      if (user?.id) {
        setLoadingMain(true);
        try {
          const res = await fetchTeam(id, user?.accessToken);

          // console.log("res.data", res.data);
          setTitle(res.data?.title);
          setName(res.data?.name);
          setImage(res.data?.imageUrl);
          setImageUrl(res.data?.imageUrl);
          setLoadingMain(false);
        } catch (err) {
          setLoadingMain(false);
          const message =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
          toast.error(message);
        }
      }
    };

    handleFetchBlog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, id]);

  return (
    <div className="addBlog">
      <div className="addBlog__main">
        <h1 className="addBlog__main__title">
          Edit Team Member
          <Link to="/dashboard/image/team">
            <i className="ti-arrow-circle-left"></i>
          </Link>
        </h1>

        {loadingMain ? (
          <Loading />
        ) : (
          <>
            <div className="addBlog__main__form">
              <div className="addBlog__main__form__item">
                <Label htmlFor="title">
                  Job Title <span>*</span>
                </Label>

                <Input
                  width="300px"
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <ErrorMessage>{inputError?.title}</ErrorMessage>
              </div>

              <div className="addBlog__main__form__item">
                <Label htmlFor="tag">
                  Name <span>*</span>
                </Label>
                <Input
                  id="name"
                  width="300px"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <ErrorMessage>{inputError?.name}</ErrorMessage>
              </div>

              <div
                className="careerApp__main__input"
                style={{ marginBottom: "20px" }}
              >
                <Label htmlFor="cover">
                  Cover Image <span>*</span>
                </Label>
                <br />
                <input
                  style={{ display: "none" }}
                  id="cover"
                  type="file"
                  onChange={(e) => handleFileUpload(e)}
                  accept="image/png, image/gif, image/jpeg, image/jpg"
                />
                <label className="careerApp__main__fileLabel" htmlFor="cover">
                  {!fileName ? "Choose File" : fileName}
                  <span>Max file size: 200kb</span>
                </label>
                <ErrorMessage>{inputError?.uploadedFile}</ErrorMessage>
              </div>

              <img
                src={image}
                alt=""
                style={{ width: "100%", maxWidth: 200, marginBottom: 30 }}
              />
            </div>

            <div className="addBlog__main__form__btn">
              <Button onClick={handleSubmit}>
                {loading ? <Loading button={true} /> : "Edit"}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EditTeam;
