import { useContext, useState } from "react";
import {
  ErrorMessage,
  Input,
  Label,
  Textarea,
} from "../components/styledComponent/formInputs";
import { toast } from "react-toastify";
import Button from "../common/Button";
import Loading from "../components/Loading";
import { Context } from "../context/MainContext";
import { Link } from "react-router-dom";
import { addTeam } from "../api/dashboardApi";

const AddTeam = () => {
  const { user } = useContext(Context);
  const [inputError, setInputError] = useState({});
  const [fileName, setfileName] = useState("");
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [uploadedFile, setuploadedFile] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];

    if (file?.size > 229715) {
      toast.warning("File too big, max size 200kb");
    } else {
      setfileName(file?.name);
      setuploadedFile(file);
    }
  };

  const validate = () => {
    let titleError = "";
    let nameError = "";
    let uploadedFileError = "";

    if (!title) {
      titleError = "title is required";
    }
    if (!name) {
      nameError = "name is required";
    }
    if (!uploadedFile) {
      uploadedFileError = "cover image is required";
    }

    if (titleError || nameError || uploadedFileError) {
      setInputError((curr) => {
        return {
          ...curr,
          title: titleError,
          name: nameError,
          uploadedFile: uploadedFileError,
          // youtube: youtubeError,
        };
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    const checkValidate = validate();
    if (checkValidate) {
      setInputError({});
      setLoading(true);
      try {
        let formData = new FormData();

        formData.append("title", title);
        formData.append("name", name);
        formData.append("uploadedFile", uploadedFile);

        addTeam(formData, user?.accessToken);

        setLoading(false);
        toast.success("Added successfully");
        // setStep(2);
        setTitle("");
        setName("");
        setuploadedFile("");
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };

  return (
    <div className="addBlog">
      <div className="addBlog__main">
        <h1 className="addBlog__main__title">
          Add To Team
          <Link to="/dashboard/image/team">
            <i className="ti-arrow-circle-left"></i>
          </Link>
        </h1>
        <div className="addBlog__main__form">
          <div className="addBlog__main__form__item">
            <Label htmlFor="title">
              Job Title <span>*</span>
            </Label>

            <Input
              width="300px"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <ErrorMessage>{inputError?.title}</ErrorMessage>
          </div>

          <div className="addBlog__main__form__item">
            <Label htmlFor="tag">
              Name <span>*</span>
            </Label>
            <Input
              id="name"
              width="300px"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <ErrorMessage>{inputError?.name}</ErrorMessage>
          </div>

          <div
            className="careerApp__main__input"
            style={{ marginBottom: "20px" }}
          >
            <Label htmlFor="cover">
              Cover Image <span>*</span>
            </Label>
            <br />
            <input
              style={{ display: "none" }}
              id="cover"
              type="file"
              onChange={(e) => handleFileUpload(e)}
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
            <label className="careerApp__main__fileLabel" htmlFor="cover">
              {!fileName ? "Choose File" : fileName}
              <span>Max file size: 200kb</span>
            </label>
            <ErrorMessage>{inputError?.uploadedFile}</ErrorMessage>
          </div>
        </div>

        <div className="addBlog__main__form__btn">
          <Button onClick={handleSubmit}>
            {" "}
            {loading ? <Loading button={true} /> : "Add"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddTeam;
